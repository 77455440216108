body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Notice {
  font-size: 10pt;
  margin: 15px auto;
  padding: 15px;
  max-width: 700px;
  color: gray;
}

.NoticeList {
  text-align: left;
  margin: 0 auto;
  max-width: 700px;
  list-style-type: '-   ';
}

.NoticeList li {
  margin: 10px 0;
}

.Section {
  border-color: gainsboro;
  border-width: 1px 0 0 0;
  border-style: dotted;
  padding: 30px 10px;
}

h2, h3 {
  font-family: "M PLUS Rounded 1c";
}
