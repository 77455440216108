.Outer {
  max-width: 1100px;
  text-align: center;
  margin: 10px auto;
  padding: 20px 0;
  border: 1px solid gainsboro;
  min-height: calc(100vh - 100px);
  position: relative;
}

.LogoOuter {
  padding: 10px;
}

.AdGarakutaToolbox {
  border: 1px solid gainsboro;
  border-radius: 5px;
  position: absolute;
  font-size: 10pt;
  padding: 15px 60px 15px 20px;
  top: 15px;
  right: 10px;
  overflow: hidden;
  background-image: url('assets/images/sora_with_piyoppi.png');
  background-position: 60px -40px;
  background-repeat: no-repeat;
  text-align: left;
}

.AdGarakutaToolbox p {
  padding: 0;
  margin: 0;
}

.AdGarakutaToolbox__Background {
  position: absolute;
  top: -50px;
  right: -40px;
}

@media screen and (max-width:1024px) {
  .AdGarakutaToolbox {
    display: none;
  }
}
