.AlertOuter {
  width: 90%;
  max-width: 900px;
  position: relative;
  padding: 20px 10px;
  margin: 0 auto;
  top: 0px;
  background-color: #880000;
  border-color: #550000;
  color: whitesmoke;
  border-style: solid;
  border-radius: 0 0 5px 5px;
  border-width: 0 3px 3px 0;
  transition: color 0.4s ease-out, background-color 0.4s ease-out, top 0.3s ease-in, top 0.3s ease-in;
  display: inline-block;
}

.AlertNotice {
  background-color: #72d0ff;
  border-color: #3386af;
  color: black;
}

.AlertHidden {
  top: -200px;
}

.AlertContainer {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
}
