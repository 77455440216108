.Footer {
  font-size: 10pt;
  padding: 20px;
  text-align: center;
  background-color: #1a3b52;
}

.FooterLink {
  color: white;
}

.FooterLink:visited {
  color: white;
}
